@import 'styles/_theme.scss'

.container
  margin: 0 auto
  padding: 30px 80px 60px
  h2
    font-size: 22px
    line-height: 26px
    color: #08487d
    font-weight: bold
    text-wrap: nowrap
    margin: 0 0 17px 0
  h3
    font-size: 16px
    line-height: 22px
    font-weight: bold
    color: #000

.closeBtn
  height: 30px
  width: 30px
  background-color: $color-subheader
  border-radius: 50%
  color: #fff
  font-size: 22px
  font-weight: bold
  border: none
  float: right

.noresult
  margin: 50px auto
  text-align: center
  font-size: 18px
  line-height: 30px
  color: $color-text

.backTxt
  font-family: "PT Sans", sans-serif
  color: #3b78c3
  font-size: 12px
  line-height: 18px
  margin-bottom: 8px
  text-decoration: underline
  cursor: pointer


@media (max-width: calc($width-desktop - 0.1px))
  .container
    padding: 24px 40px

@media (max-width: calc($width-tablet - 0.1px))
  .container
    padding: 24px 20px
