@import 'styles/_theme.scss'

.content
  margin: 24px auto 0
  width: 520px
  h2
    font-size: 22px
    line-height: 26px
    font-weight: bold
    color: $color-header
    text-wrap: nowrap
    margin: 0
  label
    margin: 0
  .creditText
    font-size: 16px
    line-height: 18px
    color: #000
    font-weight: bold
    padding: 0 30px
  .colyear
    padding: 15px 20px
    width: 206px

.table
  width: 100%
  margin: 20px 0 0
  thead
    background-color: $color-primary
    th
      font-size: 14px
      font-weight: 600
      color: #ffffff
      padding: 10px 0px
      line-height: 17px
      &:first-child
        padding-left: 20px
      &:nth-child(2)
        padding-left: 30px
        padding-right: 30px
      &:last-child
        padding-right: 20px
    td
      padding: 15px 0px
      background: $color-blue3
      font-size: 15px
      &:first-child
        padding-left: 20px
      &:nth-child(2)
        padding-left: 30px
        padding-right: 30px
      &:last-child
        padding-right: 20px
  tbody
    background-color: #F4F7FB

.stateBoard
  padding: 20px 0
  font-size: 16px
  border-radius: 5px
.singleState
  margin: 20px 0
.stateboardoptions
  margin: 10px 0
.stateboardlink
  display: block
  margin-top: 10px
  color: $color-interactive
  text-decoration: none
  transition: all ease-in-out .3s
  &:hover
    color: $color-interactive2
    text-decoration: none

.downloadButton
  display: flex
  min-width: 100px
  height: 40px
  width: 149px
  margin-right: 20px
  float: right
  align-items: center
  justify-content: center
  color: #FAFAFA
  font-size: 16px
  font-weight: bold
  line-height: 18px
  background: #3B78C3
  border: 1px solid #3B78C3
  border-radius: 20px
  opacity: 1
  transition: all ease-in-out .3s
  &:disabled
    opacity: 0.5

@media (max-width: calc($width-desktop - 0.1px))
  .content
    margin: 24px 0 0

@media (max-width: calc($width-tablet - 0.1px))
  .content
    margin: 24px 20px 0 20px
    width: unset

@media (max-width: 1450px)
  .downloadButton
    width: 120px

@media (max-width: 1370px)
  .downloadButton
    width: 100px

@media (max-width: 500px)
  .content
    .creditText
      padding: 0 10px 0 0
    .colyear
      width: 162px
