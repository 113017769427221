@import 'styles/_theme.scss'

.container
  margin: 24px 20px 20px

.h2
  font-size: 22px
  line-height: 26px
  font-weight: bold
  color: $color-header
  text-wrap: nowrap
  margin: 0

.grid

.noresult
  margin: 50px auto
  text-align: center
  font-size: 18px
  line-height: 30px
  color: $color-text

@media (max-width: calc($width-desktop - 0.1px))
  .container
    margin: 20px 0

@media (max-width: calc($width-tablet - 0.1px))
  .container
    margin: 20px
