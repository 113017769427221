@import 'styles/_theme.scss'

.container
  position: relative
  width: 1040px
  margin-left: 440px
  margin-bottom: 50px
  overflow: auto
  scrollbar-width: thin

@media (max-width: calc($width-desktop - 0.1px))
  .container
    position: relative
    width: 600px
    margin-left: 440px
    padding: 0
    overflow: unset

@media (max-width: calc($width-tablet - 0.1px))
  .container
    width: 100%
    margin-left: 0
    margin-bottom: 0
    padding: 0
